<template>
  <div id="page-view">
    <!-- <div class="breadcrumbs">
      <div class="container">
        <ul class="items">
          <li class="item home">
            <router-link to="/" title="Go to Home Page">
              Home </router-link>
          </li>
          <li class="item category112">
            <strong>About Us</strong>
          </li>
        </ul>
      </div>
    </div> -->
    <section class="bg-light  ">
      <main>
        <div class="container py-5">
          <div v-html="description" ></div>
        </div>
      </main>

    </section>
  </div>
</template>
<script>
    export default {
        name: "PageView",
        data() {
            return {
                loader: false,
                errors: [],
                description: '',
                pagecontent: [],


            };
        },
        mounted() {
            this.getPageContent();

        },
        methods: {
            getPageContent() {
                var segment_str = window.location.pathname;
                var segment_array = segment_str.split('/');
                var last_segment = segment_array.pop();
                this.loader = true;
                var method_type = "POST";
                var fetch_url = process.env.VUE_APP_URL + "customer/page/getPageDetails";
                fetch(fetch_url, {
                        method: method_type,
                        headers: {
                            "content-type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                        },
                        body: JSON.stringify({
                            slug: last_segment,
                        }),
                    })
                    .then(res => res.json())
                    .then(data => {
                        if (data.pagecontent != null) {
                            this.description = data.pagecontent.description;

                        }
                        this.loader = false;
                    })
            },


        }
    }
</script>